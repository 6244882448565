import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FileInputDropArea from '../file-input-drop-area/FileInputDropArea';
import FileInputProgress from '../file-input-progress/FileInputProgress';
import useLabels from '../../hooks/useLabels';
import { AxiosProgressEvent } from 'axios';
import { NotificationType, useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';
import { isFileSizeValid, readableFileSize } from '../../helpers/fileHelpers';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import { isValidNumber } from '../../helpers/numberHelpers';
import { DocumentRow } from '../../models/ChatTypes';
import styles from "./FileInput.module.scss";


export type FileInputProps = {
    /** List of files that has been uploaded */
    fileList?: Pick<DocumentRow, 'fileName' | 'extension'>[];

    /** Maximum number of files that can be uploaded */
    maxUploadNumber?: number;
    /** Maximum size of the file that can be uploaded */
    maxFileUploadSize?: number;
    /** Supported file extensions */
    allowedFileFormats?: string[];

    onFileUpload: (file: File, setUploadProgress: (progress: AxiosProgressEvent) => void,) => Promise<void | undefined>;
};

export type ExtendedNotificationType = NotificationType & { fileName?: string; fileSize?: string; };

const FileInput: React.FC<FileInputProps> = ({ maxUploadNumber, fileList, allowedFileFormats, maxFileUploadSize, onFileUpload, }) => {
    const labels = useLabels();
    const { errorId, registerError, removeError } = useErrorHandlerContext();

    const tempFileUploadRef = useRef<Record<string, File>>({});
    const tempProgressesRef = useRef<Record<string, AxiosProgressEvent>>();

    const [completed, setCompleted] = useState<boolean>(false);
    const [progresses, setProgresses] = useState<Record<string, AxiosProgressEvent>>();
    const [errors, setErrors] = useState<ExtendedNotificationType[]>([]); // Needed for multiple error possibility 

    const resetErrors = useCallback(() => {
        removeError(errorId);
        setErrors([]);
    }, [errorId, removeError]);

    const setMultiError = useCallback((e: ExtendedNotificationType) => setErrors(prev => [...prev, e]), []);

    const setUploadProgress = useCallback(async (fileName: string, p: AxiosProgressEvent) => {
        const prev = tempProgressesRef.current ?? {};
        const events = { ...prev, [fileName]: p };
        tempProgressesRef.current = events;
        setProgresses(events);
    }, [tempProgressesRef, setProgresses]);

    const groupErrorsByHeadline = useCallback((errors: ExtendedNotificationType[]) => {
        const mergedNotifications = errors.reduce((prev, curr, index) => {
            const matchingErrorType = prev.find(p => p.details?.statusText === curr.details?.statusText);
            const suffix = (curr.fileName || curr.fileSize) ? ' ' + [curr.fileName, curr.fileSize].filter(Boolean).join(' ') + ',' : '';
            const details = (curr?.details as any)?.data?.detail;
            const beErrorMessage = details?.[0]?.msg as string || details?.message;


            return !matchingErrorType
                ? [...prev, { ...curr, description: beErrorMessage ? beErrorMessage + ' ' + suffix : curr.description + suffix }]
                : [...prev.filter(x => x.headline !== matchingErrorType.headline), { ...matchingErrorType, description: `${matchingErrorType?.description}${suffix}` }];
        }, [] as NotificationType[]);

        if (!mergedNotifications.length) return [];
        return mergedNotifications;
    }, []);


    const progress = useMemo(() => {
        const values = Object.values(progresses ?? []);
        const total = values.reduce((acc, curr) => acc + (curr?.total ?? 0), 0);
        const loaded = values.reduce((acc, curr) => acc + (curr?.loaded ?? 0), 0);
        if (!total || !loaded) return undefined;
        return { total, loaded };
    }, [progresses]);

    const handleFilesUpload = useCallback(async (files: FileList): Promise<void> => {
        resetErrors();
        setCompleted(false);

        const $files = Object.values(files);
        if (!$files.length) return;

        const promises = $files.map((file) => {
            const fileName = file.name;
            const isDuplicate = !!tempFileUploadRef.current?.[fileName] || fileList?.some(f => (f.fileName + f.extension).toLowerCase() === fileName.toLowerCase());
            const fileSizeLimit = maxFileUploadSize ?? 20e6;
            const readableFileSizeLimit = readableFileSize(fileSizeLimit);
            const fileSize = readableFileSize(file?.size ?? 0);

            let hasUploadErrors = false;

            // Max upload number check
            if (isValidNumber(maxUploadNumber) && ((fileList?.length && fileList.length >= maxUploadNumber) || (Object.keys(tempFileUploadRef.current).length >= maxUploadNumber))) {
                hasUploadErrors = true;
                setMultiError({ type: 'notification', headline: labels.uploadLimitErrorHeadline, description: labels.uploadLimitErrorMessage(maxUploadNumber) });
            }
            // File size check
            if (maxFileUploadSize && !isFileSizeValid(file, maxFileUploadSize)) {
                hasUploadErrors = true;
                setMultiError({ type: 'notification', headline: labels.uploadErrorHeadline, description: labels.exceededDocSizeError(readableFileSizeLimit), fileName: file.name, fileSize });
            }

            // Duplicate file check
            if (isDuplicate) {
                hasUploadErrors = true;
                const duplicateErrorMessage = labels.duplicateDocumentError;
                setMultiError({ type: 'notification', headline: labels.duplicateNameErrorHeadline, description: duplicateErrorMessage, fileName: file.name });
            };

            if (hasUploadErrors) return null;

            tempFileUploadRef.current = { ...tempFileUploadRef.current, [file.name]: file };

            return onFileUpload(file, p => setUploadProgress(file.name, p));
        }).filter(Boolean);

        try {
            await Promise.all(promises);
        }
        catch (e: any) {
            setMultiError({ type: 'notification', headline: labels.uploadErrorHeadline, description: labels.uploadErrorMessage, details: e });
        }
        finally {
            setCompleted(true);
            // tempFileUploadRef.current = {};
            return Promise.resolve();
        }
    }, [resetErrors, fileList, maxFileUploadSize, maxUploadNumber, onFileUpload, setMultiError, labels, setUploadProgress]);


    // Set error once loading is done
    useEffect(() => {
        if (((progress?.loaded === progress?.total && errors.length) || (progresses === undefined && errors.length))) {
            const notifications = groupErrorsByHeadline(errors);
            registerError({ [errorId]: { type: 'notifications', notifications } });
        }
    }, [errorId, errors, groupErrorsByHeadline, progress, progresses, registerError, removeError, resetErrors]);


    return <div className={styles['file-input-wrapper']}>
        <FileInputDropArea
            multiple={(maxFileUploadSize || NaN) > 1}
            accept={allowedFileFormats}
            onFilesSelected={handleFilesUpload}
            onInvalidFiles={() => setMultiError({ type: 'notification', headline: labels.invalidDocumentTypeHeader, description: labels.invalidDocumentTypeMessage(allowedFileFormats) })}
        />
        <FileInputProgress
            completed={completed}
            hasError={!!errors.length}
            className={styles['file-drop-progress']}
            progress={progress}
            onDoneLoading={() => {
                tempProgressesRef.current = undefined;
                tempFileUploadRef.current = {};
            }}
            onDelayedDoneLoading={() => {
                setCompleted(false);
                setProgresses(undefined);
            }}
        />

        <ErrorHandler.Notifications className={styles.error} id={errorId} />
    </div>;
};

export default FileInput;